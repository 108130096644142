/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";
import ReactGA from "react-ga";
import Header from "./header";
import "./layout.css";

export default class Layout extends React.Component {
  public render(): JSX.Element {
    if (typeof window !== "undefined") {
      ReactGA.initialize("UA-16964853-1");
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    return <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={data => (
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main className="main">
            <div>{this.props.children}</div>
            <footer>
              <p><span>© {new Date().getFullYear()}, Idaho Edokpayi</span>&nbsp;<Link to="#top"><FontAwesomeIcon icon={faArrowCircleUp} /></Link> </p>
            </footer>
          </main>
        </>
      )}
    />;
  }
}

