import { faFacebookSquare, faLinkedin, faMedium, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faBars, faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import * as React from "react";
import "./header.css";

interface IHeaderProps {
  siteTitle: string;
}
interface IHeaderState {
  showMenu: boolean;
}
export default class Header extends React.Component<IHeaderProps, IHeaderState>{
  private ToggleMenuVisibilityFunc: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void = this.ToggleMenuVisibility.bind(this);

  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      showMenu: false
    };
  }
  public render(): JSX.Element {
    const menuItemStyle = this.state.showMenu ? "mobile-flex" : "mobile-hide";
    return <header id="top">
      <div className="title-head">
        <div className="title-wrapper">
          <h1 style={{ margin: 0 }}>
            <Link
              to="/"
              style={{
                textDecoration: `none`,
              }}
            >
              {this.props.siteTitle}
            </Link>
          </h1>
          <div className={"menu"}>
            <a href={"#"} className="hamburger-menu" onClick={this.ToggleMenuVisibilityFunc} ><FontAwesomeIcon icon={faBars} size={"lg"} /></a>
            <a href="http://eepurl.com/bG0W3H" className={menuItemStyle} target="_blank"><FontAwesomeIcon icon={faEnvelopeSquare} size={"lg"} /></a>
            <a href="https://twitter.com/WhoIsIdaho" className={menuItemStyle} target="_blank"><FontAwesomeIcon icon={faTwitterSquare} size={"lg"} /></a>
            <a href="https://www.facebook.com/whoisidahoblog/" className={menuItemStyle} target="_blank"><FontAwesomeIcon icon={faFacebookSquare} size={"lg"} /></a>
            <a href="https://medium.com/whoisidaho" className={menuItemStyle} target="_blank"><FontAwesomeIcon icon={faMedium} size={"lg"} /></a>
            <a href="https://www.linkedin.com/in/whoisidaho/" className={menuItemStyle} target="_blank"><FontAwesomeIcon icon={faLinkedin} size={"lg"} /></a>
          </div>
        </div>
      </div>
    </header>;
  }
  private ToggleMenuVisibility(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    this.setState({ showMenu: !this.state.showMenu });
  }
}

